@use '@/styles/utils/mixins.scss' as *;

.mediaListingWrapper {
  display: flex;
  flex-direction: column;
  gap: 6.25rem;
  .searchFilterContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 3.75rem;
  }
  .searchWrapper {
    display: flex;
    width: 100%;
    max-width: 61.625rem;
    flex-direction: column;
    gap: 1.25rem;
    position: relative;

    @include mob() {
      width: calc(100% + 2.5rem);
      margin: 0 -1.25rem;
    }

    .searchContainer {
      width: 100%;
      display: flex;
      gap: 1.25rem;
    }

    .searchBox {
      display: flex;
      border: 1px solid $neutral06;
      border-radius: 1.25rem;
      overflow: hidden;
      padding: 0.25rem 1rem;
      padding-left: 2rem;
      align-items: center;
      background-color: transparent;
      width: 100%;
      flex: 1 1 auto;
      .searchBoxInput {
        padding: 0;
        border: 0;
        height: auto;
        min-height: auto;
        font-size: 1rem;
        line-height: normal;
        color: $neutral08;
        font-weight: 500;
        outline: 0;
        background-color: transparent;
        &::placeholder {
          color: $neutral06;
          opacity: 1;
        }
        &:focus {
          box-shadow: none;
        }
      }
      .searchBtn {
        padding: 1rem;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        height: 100%;
        border-radius: 1.25rem;
        transition: all 0.2s ease-in-out;
        background-color: transparent;
        &:focus {
          box-shadow: none;
        }
        &:hover,
        &:focus,
        &:active {
          background: $white !important;
        }
      }
    }

    .filterBtn {
      background-color: $neutral01;
      border: 1px solid $neutral06;
      color: $dark;
      font-size: 1rem;
      font-weight: 500;
      padding: 0.875rem 1.5rem;
      -webkit-transition: all 450ms ease-in-out;
      -moz-transition: all 450ms ease-in-out;
      -o-transition: all 450ms ease-in-out;
      -ms-transition: all 450ms ease-in-out;
      transition: all 450ms ease-in-out;
      display: inline-flex;
      gap: 0.25rem;
      border-radius: 1.25rem;
      text-align: center;
      align-items: center;
      justify-content: center;
      max-width: 15.625rem;
      width: 100%;
      cursor: pointer;
      position: relative;
      z-index: 99;
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;

      @include tab() {
        padding: 0.875rem 1.25rem;
        max-width: none;
        width: max-content;
      }

      &:hover,
      &:focus,
      &:active {
        border-color: $primaryAlt;
      }

      .btnText {
        display: inline-flex;
        @include tab() {
          display: none;
        }
      }
      .btnIcon {
        display: none;
        @include tab() {
          display: inline-flex;
        }
      }
    }

    .filterMenu {
      max-width: 15.625rem;
      width: 50%;
      border: 1px solid transparent;
      border-radius: 1.25rem;
      display: flex;
      flex-direction: column;
      background-color: $neutral01;
      position: absolute;
      right: 0;
      top: 3.5rem;
      overflow: hidden;
      height: 0;
      opacity: 0;
      transition:
        height 0.5s ease-in-out,
        opacity 0.1s ease-in-out,
        width 0.1s ease-in-out;
      z-index: 2;

      @include tab() {
        position: static;
        top: auto;
        right: auto;
        max-width: 100%;
        width: 100%;
      }

      &.open {
        height: fit-content;
        opacity: 1;
        border-color: $neutral06;
        width: 100%;
      }

      .filterMenuItem {
        font-size: 1rem;
        font-weight: bold;
        color: $dark;
        padding: 1rem 1.25rem;
        transition: all 450ms ease-in-out;
        width: 100%;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
          color: $primary;
        }
      }
    }
  }
  .mediaListContainer {
    display: flex;
    flex-direction: column;
    gap: 3.75rem;
    border-top: 1px solid $primaryAlt;
    padding-top: 6.25rem;
  }
  .filterBtnList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    margin-bottom: 6.25rem;
    scroll-margin-top: 7.5rem;

    @include tab() {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mob() {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .filterBtnBig {
    border-radius: 1.25rem;
    -webkit-box-shadow: 0px 0.375rem 1rem 0px rgba($black, 0.12);
    -moz-box-shadow: 0px 0.375rem 1rem 0px rgba($black, 0.12);
    box-shadow: 0px 0.375rem 1rem 0px rgba($black, 0.12);
    padding: 2.5rem 1.875rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    background-color: $white;
    border: 1px solid $white;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active,
    &.active {
      border-color: $primary;
      background-color: $primary;
      color: $white;
    }
  }
  .mediaList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    @include tab() {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mob() {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .customPagination {
    :global {
      .ant-pagination-item {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 2rem;
        height: 2rem;
        margin-inline-end: 0.5rem;

        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 0.5rem;
        outline: 0;
        cursor: pointer;
        user-select: none;
        color: $dark;
        transition: all 0.2s ease-in-out;
      }
      .ant-pagination-item-active {
        background-color: $primary;
        a {
          color: $white;
          &:hover,
          &:active,
          &:focus {
            color: $white;
          }
        }
      }
      .ant-pagination-prev,
      .ant-pagination-next {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        color: $dark;
        &:hover,
        &:focus,
        &:active {
          color: $primary;
        }
        &.ant-pagination-disabled {
          color: #d7d7d7;
        }
      }
      .ant-pagination-prev {
        margin-right: 1.5rem;
      }
      .ant-pagination-next {
        margin-left: 1.5rem;
      }
    }
  }
}
